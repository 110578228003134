<template>
  <div class="map-wrapper transform rotate-180">
    <svg
      width="360"
      height="400"
      viewBox="0 0 500 524"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      @click="toggleMap"
    >
      <!-- Outer ring -->
      <path
        d="M96.6183 50.8882L250 1.05146L403.382 50.8882L498.177 181.362V342.638L403.382 473.112L250 522.949L96.6183 473.112L1.8232 342.638V181.362L96.6183 50.8882Z"
        stroke="#B3B3B3"
        stroke-width="2"
      />
      <!-- Products -->
      <!-- Lokotrack™ ST2.3 -->
      <NuxtLink
        :to="
          content.map[7].link.cached_url.indexOf('/') === 0
            ? content.map[7].link.cached_url
            : `/${content.map[7].link.cached_url}`
        "
      >
        <circle
          data-bs-toggle="tooltip"
          :title="content.map[7].text"
          :class="{ active: current === 7 }"
          r="32.5"
          transform="matrix(1 0 0 -1 186.5 68.5)"
          fill="#E6E6E6"
          stroke="#B3B3B3"
          stroke-width="2"
        />
      </NuxtLink>
      <!-- Lokotrack™ LT1213S -->
      <NuxtLink
        :to="
          content.map[8].link.cached_url.indexOf('/') === 0
            ? content.map[8].link.cached_url
            : `/${content.map[8].link.cached_url}`
        "
      >
        <circle
          data-bs-toggle="tooltip"
          :title="content.map[8].text"
          :class="{ active: current === 8 }"
          r="32.5"
          transform="matrix(1 0 0 -1 264.5 55.5)"
          fill="#E6E6E6"
          stroke="#B3B3B3"
          stroke-width="2"
        />
      </NuxtLink>
      <!-- Lokotrack™ LT96 Urban -->
      <NuxtLink
        :to="
          content.map[9].link.cached_url.indexOf('/') === 0
            ? content.map[9].link.cached_url
            : `/${content.map[9].link.cached_url}`
        "
      >
        <circle
          data-bs-toggle="tooltip"
          :title="content.map[9].text"
          :class="{ active: current === 9 }"
          r="32.5"
          transform="matrix(1 0 0 -1 337.5 80.5)"
          fill="#E6E6E6"
          stroke="#B3B3B3"
          stroke-width="2"
        />
      </NuxtLink>
      <!-- Lokotrack™ LT120E -->
      <NuxtLink
        :to="
          content.map[10].link.cached_url.indexOf('/') === 0
            ? content.map[10].link.cached_url
            : `/${content.map[10].link.cached_url}`
        "
      >
        <circle
          data-bs-toggle="tooltip"
          :title="content.map[10].text"
          :class="{ active: current === 10 }"
          r="32.5"
          transform="matrix(1 0 0 -1 400.5 123.5)"
          fill="#E6E6E6"
          stroke="#B3B3B3"
          stroke-width="2"
        />
      </NuxtLink>
      <!-- Lokotrack™ LT330D -->
      <NuxtLink
        :to="
          content.map[11].link.cached_url.indexOf('/') === 0
            ? content.map[11].link.cached_url
            : `/${content.map[11].link.cached_url}`
        "
      >
        <circle
          data-bs-toggle="tooltip"
          :title="content.map[11].text"
          :class="{ active: current === 11 }"
          r="32.5"
          transform="matrix(1 0 0 -1 442.5 188.5)"
          fill="#E6E6E6"
          stroke="#B3B3B3"
          stroke-width="2"
        />
      </NuxtLink>
      <!-- Lokotrack™ ST4.10E -->
      <NuxtLink
        :to="
          content.map[12].link.cached_url.indexOf('/') === 0
            ? content.map[12].link.cached_url
            : `/${content.map[12].link.cached_url}`
        "
      >
        <circle
          data-bs-toggle="tooltip"
          :title="content.map[12].text"
          :class="{ active: current === 12 }"
          cx="447.5"
          cy="266.205"
          r="32.5"
          fill="#E6E6E6"
          stroke="#B3B3B3"
          stroke-width="2"
        />
      </NuxtLink>
      <!-- Lokotrack™ ST4.8 -->
      <NuxtLink
        :to="
          content.map[13].link.cached_url.indexOf('/') === 0
            ? content.map[13].link.cached_url
            : `/${content.map[13].link.cached_url}`
        "
      >
        <circle
          data-bs-toggle="tooltip"
          :title="content.map[13].text"
          :class="{ active: current === 13 }"
          cx="427.5"
          cy="342.5"
          r="32.5"
          fill="#E6E6E6"
          stroke="#B3B3B3"
          stroke-width="2"
        />
      </NuxtLink>
      <!-- Lokotrack™ LT200HPX -->
      <NuxtLink
        :to="
          content.map[14].link.cached_url.indexOf('/') === 0
            ? content.map[14].link.cached_url
            : `/${content.map[14].link.cached_url}`
        "
      >
        <circle
          data-bs-toggle="tooltip"
          :title="content.map[14].text"
          :class="{ active: current === 14 }"
          cx="385.5"
          cy="408.5"
          r="32.5"
          fill="#E6E6E6"
          stroke="#B3B3B3"
          stroke-width="2"
        />
      </NuxtLink>
      <!-- Lokotrack™ LT200HP -->
      <NuxtLink
        :to="
          content.map[15].link.cached_url.indexOf('/') === 0
            ? content.map[15].link.cached_url
            : `/${content.map[15].link.cached_url}`
        "
      >
        <circle
          data-bs-toggle="tooltip"
          :title="content.map[15].text"
          :class="{ active: current === 15 }"
          cx="314.5"
          cy="449.5"
          r="32.5"
          fill="#E6E6E6"
          stroke="#B3B3B3"
          stroke-width="2"
        />
      </NuxtLink>
      <!-- Lokotrack™ ST2.8 -->
      <NuxtLink
        :to="
          content.map[16].link.cached_url.indexOf('/') === 0
            ? content.map[16].link.cached_url
            : `/${content.map[16].link.cached_url}`
        "
      >
        <circle
          data-bs-toggle="tooltip"
          :title="content.map[16].text"
          :class="{ active: current === 16 }"
          cx="232.5"
          cy="461.5"
          r="32.5"
          fill="#E6E6E6"
          stroke="#B3B3B3"
          stroke-width="2"
        />
      </NuxtLink>
      <!-- Lokotrack™ LT106 -->
      <NuxtLink
        :to="
          content.map[17].link.cached_url.indexOf('/') === 0
            ? content.map[17].link.cached_url
            : `/${content.map[17].link.cached_url}`
        "
      >
        <circle
          data-bs-toggle="tooltip"
          :title="content.map[17].text"
          :class="{ active: current === 17 }"
          cx="155.5"
          cy="441.5"
          r="32.5"
          fill="#E6E6E6"
          stroke="#B3B3B3"
          stroke-width="2"
        />
      </NuxtLink>

      <!-- Lokotrack Gallery -->
      <NuxtLink
        :to="
          content.map[4].link.cached_url.indexOf('/') === 0
            ? content.map[4].link.cached_url
            : `/${content.map[4].link.cached_url}`
        "
      >
        <rect
          data-bs-toggle="tooltip"
          :title="content.map[4].text"
          :class="{ active: current === 4 }"
          x="12.396"
          y="245.4"
          width="22.7074"
          height="34.148"
          transform="rotate(0 0 0)"
          fill="#E6E6E6"
          stroke="#B3B3B3"
          stroke-width="2"
        />
      </NuxtLink>

      <!-- Lokotrack Promo Shop -->
      <NuxtLink
        :to="
          content.map[3].link.cached_url.indexOf('/') === 0
            ? content.map[3].link.cached_url
            : `/${content.map[3].link.cached_url}`
        "
      >
        <rect
          data-bs-toggle="tooltip"
          :title="content.map[3].text"
          :class="{ active: current === 3 }"
          x="3.0"
          y="30.0"
          width="22.7074"
          height="34.148"
          transform="matrix(0.811439 0.584437 0.584437 -0.811439 40.3959 145.105)"
          fill="#E6E6E6"
          stroke="#B3B3B3"
          stroke-width="2"
        />
      </NuxtLink>

      <!-- Lokotrack Media -->
      <NuxtLink
        :to="
          content.map[6].link.cached_url.indexOf('/') === 0
            ? content.map[6].link.cached_url
            : `/${content.map[6].link.cached_url}`
        "
      >
        <rect
          data-bs-toggle="tooltip"
          :title="content.map[6].text"
          :class="{ active: current === 6 }"
          x="3.0"
          y="-25.0"
          width="22.7074"
          height="34.148"
          transform="matrix(0.811439 0.584437 0.584437 -0.811439 40.3959 145.105)"
          fill="#E6E6E6"
          stroke="#B3B3B3"
          stroke-width="2"
        />
      </NuxtLink>

      <!-- Lokotrack Parts -->
      <NuxtLink
        :to="
          content.map[2].link.cached_url.indexOf('/') === 0
            ? content.map[2].link.cached_url
            : `/${content.map[2].link.cached_url}`
        "
      >
        <rect
          data-bs-toggle="tooltip"
          :title="content.map[2].text"
          :class="{ active: current === 2 }"
          x="1.39588"
          y="-0.227002"
          width="22.7074"
          height="34.148"
          transform="matrix(0.811439 0.584437 0.584437 -0.811439 137.396 207.105)"
          fill="#E6E6E6"
          stroke="#B3B3B3"
          stroke-width="2"
        />
      </NuxtLink>

      <!-- Lokotrack Rental -->
      <NuxtLink
        :to="
          content.map[26].link.cached_url.indexOf('/') === 0
            ? content.map[26].link.cached_url
            : `/${content.map[26].link.cached_url}`
        "
      >
        <rect
          data-bs-toggle="tooltip"
          :title="content.map[26].text"
          :class="{ active: current === 26 }"
          x="223.0"
          y="4.0"
          width="22.7074"
          height="34.148"
          transform="matrix(0.811439 0.584437 0.584437 -0.811439 137.396 207.105)"
          fill="#E6E6E6"
          stroke="#B3B3B3"
          stroke-width="2"
        />
      </NuxtLink>

      <!-- Lokotrack Financing -->
      <NuxtLink
        :to="
          content.map[25].link.cached_url.indexOf('/') === 0
            ? content.map[25].link.cached_url
            : `/${content.map[25].link.cached_url}`
        "
      >
        <rect
          data-bs-toggle="tooltip"
          :title="content.map[25].text"
          :class="{ active: current === 25 }"
          x="361.0"
          y="320.0"
          width="22.7074"
          height="34.148"
          transform="rotate(-35.7632 139.396 317.594)"
          fill="#E6E6E6"
          stroke="#B3B3B3"
          stroke-width="2"
        />
      </NuxtLink>

      <!-- Lokotrack Entrance -->
      <NuxtLink
        :to="
          content.map[18].link.cached_url.indexOf('/') === 0
            ? content.map[18].link.cached_url
            : `/${content.map[18].link.cached_url}`
        "
      >
        <rect
          data-bs-toggle="tooltip"
          :title="content.map[18].text"
          :class="{ active: current === 18 }"
          x="65"
          y="255"
          width="15"
          height="16"
          fill="#E6E6E6"
          stroke="#B3B3B3"
          stroke-width="2"
        />
      </NuxtLink>

      <!-- Lokotrack Services -->
      <NuxtLink
        :to="
          content.map[5].link.cached_url.indexOf('/') === 0
            ? content.map[5].link.cached_url
            : `/${content.map[5].link.cached_url}`
        "
      >
        <rect
          data-bs-toggle="tooltip"
          :title="content.map[5].text"
          :class="{ active: current === 5 }"
          x="139.396"
          y="317.594"
          width="22.7074"
          height="34.148"
          transform="rotate(-35.7632 139.396 317.594)"
          fill="#E6E6E6"
          stroke="#B3B3B3"
          stroke-width="2"
        />
      </NuxtLink>

      <!-- Lokotrack Marketing -->
      <NuxtLink
        :to="
          content.map[0].link.cached_url.indexOf('/') === 0
            ? content.map[0].link.cached_url
            : `/${content.map[0].link.cached_url}`
        "
      >
        <rect
          data-bs-toggle="tooltip"
          :title="content.map[0].text"
          :class="{ active: current === 0 }"
          x="17.3959"
          y="339.666"
          width="22.7074"
          height="34.148"
          transform="rotate(-35.7632 17.3959 339.666)"
          fill="#E6E6E6"
          stroke="#B3B3B3"
          stroke-width="2"
        />
      </NuxtLink>

      <!-- Lokotrack Training -->
      <NuxtLink
        :to="
          content.map[1].link.cached_url.indexOf('/') === 0
            ? content.map[1].link.cached_url
            : `/${content.map[1].link.cached_url}`
        "
      >
        <rect
          data-bs-toggle="tooltip"
          :title="content.map[1].text"
          :class="{ active: current === 1 }"
          x="49.3959"
          y="383.666"
          width="22.7074"
          height="34.148"
          transform="rotate(-35.7632 49.3959 383.666)"
          fill="#E6E6E6"
          stroke="#B3B3B3"
          stroke-width="2"
        />
      </NuxtLink>

      <!-- Lokotrack Tunnel -->
      <NuxtLink
        :to="
          content.map[19].link.cached_url.indexOf('/') === 0
            ? content.map[19].link.cached_url
            : `/${content.map[19].link.cached_url}`
        "
      >
        <rect
          data-bs-toggle="tooltip"
          :title="content.map[19].text"
          :class="{ active: current === 19 }"
          x="36.3959"
          y="436.666"
          width="11.35"
          height="34.148"
          transform="rotate(-35.7632 49.3959 383.666)"
          fill="#E6E6E6"
          stroke="#B3B3B3"
          stroke-width="2"
        />
      </NuxtLink>
      <!-- STAGE -->
      <!-- Lokotrack Stage (Train overview) -->
      <NuxtLink
        :to="
          content.map[20].link.cached_url.indexOf('/') === 0
            ? content.map[20].link.cached_url
            : `/${content.map[20].link.cached_url}`
        "
      >
        <path d="M1.5 235H64.5V290H1.5" stroke="#B3B3B3" stroke-width="2" />
        <path
          data-bs-toggle="tooltip"
          :title="content.map[20].text"
          :class="{ active: current === 20 }"
          class="stage"
          d="M195.291 186.768L249.817 169.051L304.343 186.768L338.041 233.151V290.483L304.343 336.865L249.817 354.582L195.291 336.865L161.592 290.483V233.151L195.291 186.768Z"
          fill="#E6E6E6"
          stroke="#B3B3B3"
          stroke-width="2"
        />
      </NuxtLink>

      <!-- Stage Train left -->
      <NuxtLink
        :to="
          content.map[24].link.cached_url.indexOf('/') === 0
            ? content.map[24].link.cached_url
            : `/${content.map[24].link.cached_url}`
        "
      >
        <circle
          data-bs-toggle="tooltip"
          :title="content.map[24].text"
          :class="{ active: current === 24 }"
          r="21.5"
          transform="matrix(1 0 0 -1 213.5 225.5)"
          fill="#E6E6E6"
          stroke="#B3B3B3"
          stroke-width="2"
        />
      </NuxtLink>
      <!-- Stage LT1213S-->
      <NuxtLink
        :to="
          content.map[23].link.cached_url.indexOf('/') === 0
            ? content.map[23].link.cached_url
            : `/${content.map[23].link.cached_url}`
        "
      >
        <circle
          data-bs-toggle="tooltip"
          :title="content.map[23].text"
          :class="{ active: current === 23 }"
          r="21.5"
          transform="matrix(1 0 0 -1 286.5 225.5)"
          fill="#E6E6E6"
          stroke="#B3B3B3"
          stroke-width="2"
        />
      </NuxtLink>
      <!-- Stage Train right -->
      <NuxtLink
        :to="
          content.map[22].link.cached_url.indexOf('/') === 0
            ? content.map[22].link.cached_url
            : `/${content.map[22].link.cached_url}`
        "
      >
        <circle
          data-bs-toggle="tooltip"
          :title="content.map[22].text"
          :class="{ active: current === 22 }"
          r="21.5"
          transform="matrix(1 0 0 -1 213.5 297.5)"
          fill="#E6E6E6"
          stroke="#B3B3B3"
          stroke-width="2"
        />
      </NuxtLink>
      <!-- Stage ST2.3 -->
      <NuxtLink
        :to="
          content.map[21].link.cached_url.indexOf('/') === 0
            ? content.map[21].link.cached_url
            : `/${content.map[21].link.cached_url}`
        "
      >
        <circle
          data-bs-toggle="tooltip"
          :title="content.map[21].text"
          :class="{ active: current === 21 }"
          r="21.5"
          transform="matrix(1 0 0 -1 286.5 297.5)"
          fill="#E6E6E6"
          stroke="#B3B3B3"
          stroke-width="2"
        />
      </NuxtLink>
    </svg>
  </div>
</template>

<script setup>
const { $bootstrap } = useNuxtApp();
defineProps({
  content: {
    type: Object,
    required: true,
  },
  current: {
    type: Number,
    required: true,
  },
});
const map = useMap();
const toggleMap = () => {
  map.value = !map.value;
};

onMounted(() => {
  const tooltipTriggerList = document.querySelectorAll(
    '[data-bs-toggle="tooltip"]'
  );
  const tooltipList = [...tooltipTriggerList].map(
    (tooltipTriggerEl) =>
      new $bootstrap.Tooltip(tooltipTriggerEl, {
        boundary: document.body,
        trigger: "hover focus",
        container: ".map-modal-container",
      })
  );
});
</script>

<style lang="scss" scoped>
circle:focus,
rect:focus,
.stage:focus {
  outline: none;
}
circle:hover {
  fill: white;
  stroke: #00e2b2;
}

rect:hover:not(.non-active) {
  fill: white;
  stroke: #00e2b2;
}

.stage:hover {
  fill: white;
  stroke: #00e2b2;
}

.active {
  fill: #00e2b2;
  stroke: #00e2b2;
}
</style>
